import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import SnackbarComposables from '../composables/SnackbarComposables';
import { useApi } from '../composables/useApi';

const IncheckReferenceService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();
    const { t, locale } = useI18n();
    const { addSnackbar } = SnackbarComposables();

    /* Methods */
    const updateIncheckReference = async(incheckReferenceId, payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put(`incheck-reference/${incheckReferenceId}`, payload);
        if(res)
        {
            if(res.data && res.data.incheck_reference)
            {
                addSnackbar('success', t('product.update.createdMessage', store.state.locale));
                return 200;
            }
            else
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const destroyIncheckReference = async(incheckReferenceId) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.delete(`incheck-reference/delete/${incheckReferenceId}`);
        if(res)
        {
            if(res.status === 200)
            {
                return 200;
            }
            else
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const setNoMatchInIncheckReference = async(incheckReferenceId, payload) =>
    {   
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.patch(`incheck-reference/set-no-match/${incheckReferenceId}`, payload);
        if(res)
        {
            if(res.data && res.data.incheck_reference)
            {
                addSnackbar('success', t('product.update.no_match_created', store.state.locale));
                return res.data;
            }
            else
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }

    };
    const updatePreviousResults = async (incheckReferenceId, payload) =>
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put(`incheck-reference/update-results/${incheckReferenceId}`, payload);
        if(res.data) return res.data;
        return res;
    };

    /* Hooks */
    locale.value = store.state.locale;

    return {
        /* Methods */
        updateIncheckReference,
        destroyIncheckReference,
        setNoMatchInIncheckReference,
        updatePreviousResults
    }

};

export default IncheckReferenceService;

