import { useZdhcAPIClient } from '../../composables/useZdhcApiClient';
import { useStore } from "vuex";
import moment from 'moment';

const ZdhcService = () => 
{
    /* Data */
    const zdhcApiClient = useZdhcAPIClient()
    const store = useStore();

    /* Methods */
    // Retrieve Gateway Access Token
    const inCheckAccessToken = async (UserAccessKey) => 
    {
        store.commit('errors/CLEAR_STATE');
        // Setting authenticationKey based on APP_ENV variable
        const authenticationKey = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_AUTH_KEY_BETA : process.env.VUE_APP_ZDHC_AUTH_KEY;
        // Setting authenticationKey based on APP_ENV variable
        //const UserAccessKey = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_USER_KEY_BETA : process.env.VUE_APP_ZDHC_USER_KEY;
            const res = await zdhcApiClient.post('oauth/tokenbykey', {
            "authenticationKey": authenticationKey,
            "UserAccessKey": UserAccessKey
        });
        if(res)
        {
            if(res.data.result.success === true)
            {
                if(res.data.token)
                {
                    if(res.data.token.tokenType && res.data.token.tokenType === 'bearer')
                    {
                        zdhcApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.accessToken}`;
                        zdhcApiClient.defaults.headers.common['accessToken'] = `${res.data.token.accessToken}`;
                    }
                }
                return {status: 'success'};
            }
            else
            {
                return {status: 'error'};
            }
        }
    };
    const PurchaseInCheckSubscription = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v5/PurchaseInCheckSubscription?OrgGUID=${organizationGUID}`);
        if(res)
        {
            if (res.data?.result?.Success) return 200;
            else return res;
        }
    };
    const inCheckReport = async (payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v5/incheckReport`, payload);
        if(res)
        {
            if(res.data.result?.Success) 
            {
                return res.data
            }
            else if(res.data.result?.errorMessage)
            {
                return 404;
            } 
        }
    };
    const inCheckSubscriptionStatus = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.get(`v5/InCheckSubscriptionStatus?organizationGUID=${organizationGUID}`);
        if(res.data.InCheckStatus)
        {
            if(res.data.InCheckStatus === 'Active')
            {
                const expirationDate = res.data.InCheckExpirationDate;
                if(moment(expirationDate).isSameOrAfter(moment()))
                {
                    //moment('2038-01-19 00:00:00')
                    return {
                        status: 'ok',
                        expirationDate: moment(expirationDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                }
                else
                {
                    // renew subscription
                    return renewInCheckSubscription(organizationGUID);
                }
            }
            else if(res.data.InCheckStatus === 'Inactive')
            {
                return renewInCheckSubscription(organizationGUID);
            }
            else if(res.data.InCheckStatus === 'Subscribed but not yet activated/used')
            {
                return {
                    status: 'ok',
                    expirationDate: null
                }
            }
            else if(res.data.InCheckStatus.includes('No subscription found'))
            {
                const purhcaseRes = await PurchaseInCheckSubscription(organizationGUID);
                if(purhcaseRes === 200)  return inCheckSubscriptionStatus(organizationGUID);
                else 
                {
                    return {
                        status: 'ko',
                        expirationDate: null
                    }
                }
            }
            else 
            {
                return {
                    status: 'ko',
                    expirationDate: null
                }
            }
        }
        return {
            status: 'ko',
            expirationDate: null
        }
    };
    const renewInCheckSubscription = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v5/RenewInCheckSubscription?OrgGUID=${organizationGUID}`);
        if(res.data.result)
        {
            if(res.data.result.Success)
            {
                var date = res.data.result.Message.substr(res.data.result.Message.length - 10); 
                return {
                    status: 'ok',
                    expirationDate: moment(date).format('YYYY-MM-DD HH:mm:ss')
                }
            }
        }
        return {
            status: 'ko',
            expirationDate: null
        };
    };
    

    return {
        /* Methods */
        inCheckAccessToken,
        PurchaseInCheckSubscription,
        inCheckReport,
        inCheckSubscriptionStatus,
        renewInCheckSubscription
    }
}
export default ZdhcService;





